import React, { useEffect, useState } from "react";
import "./edit.css";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import header_image_thumg from "../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  changeClubPassword,
  clubUpdate,
  viewClubDetail,
} from "../../helpers/Redux/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import * as moment from "moment";

const EditClubProfile = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const previousPageCount = location.state?.page;
  const [showimage, setShowImage] = useState(header_image_thumg);
  const [startDate, setStartDate] = useState(new Date());
  const [sendImage, setSendImage] = useState(null);
  const [status, setstatus] = useState(0);

  const [loading, setLoading] = useState(false);

  const clubId = localStorage.getItem("user_id");

  const handleImageUpload = (e) => {
    let file = e.target.files[0];
    setShowImage(URL.createObjectURL(file));
    setSendImage(file);
  };
  const [passwordValues, setPasswordValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const passwordValidationSchema = Yup.object({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });
  const handlePasswordSubmit = async (values, { resetForm }) => {
    const requestOptions = {
      clubId: clubId,
      oldPassword: values.currentPassword,
      newPassword: values.newPassword,
    };
    try {
      const res = await dispatch(changeClubPassword(requestOptions));
      if (res?.payload?.status === true) {
        toast.success(res?.payload?.message);
        navigate(-1);
      } else {
        toast.error(res?.payload?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [initialValue, setInitialValue] = useState({
    clubName: "",
    email: "",
    startDate: "",
    headquartersName: "",
    registrationNumber: "",
    address: "",
    description: "",
    // chatRestriction: 0,
  });
  console.log(initialValue);

  const handlegetdata = () => {
    setLoading(true);
    try {
      dispatch(viewClubDetail(clubId)).then((res) => {
        if (res?.payload?.status === true) {
          setLoading(false);
          setInitialValue({
            clubName: res?.payload?.data.name,
            email: res?.payload?.data.email,
            startDate: res?.payload?.data.start_date,
            headquartersName: res?.payload?.data.club_headquarters_name,
            registrationNumber: res?.payload?.data.registration_no,
            address: res?.payload?.data.address,
            description: res?.payload?.data.description,
            // chatRestriction: res?.payload?.data.chat_restriction,
          });
          if (res?.payload?.data?.club_logo) {
            setShowImage(res?.payload?.data?.club_logo);
            setSendImage(res?.payload?.data?.club_logo);
          }
        } else {
          setLoading(false);
          setInitialValue(initialValue);
        }
      });
    } catch (error) {
      console.log(error, "from view Club details");
    }
  };
  useEffect(() => {
    handlegetdata();
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    console.log(sendImage, "sendImage");

    // Create a new FormData object
    const formData = new FormData();

    // Append form values to FormData
    formData.append("clubName", values.clubName);
    formData.append("email", values.email);
    formData.append(
      "startDate",
      moment(values?.start_date).format("YYYY/MM/DD")
    );
    formData.append("headquarterName", values.headquartersName);
    formData.append("registrationNumber", values.registrationNumber);
    formData.append("address", values.address);
    formData.append("description", values.description);
    // formData.append('chatRestriction', values.chatRestriction);
    formData.append("club_logo", sendImage); // Append the image file

    // Create request options with the FormData
    const requestOptions = {
      clubId: clubId,
      body: {
        method: "POST",
        body: formData,
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      },
    };

    try {
      dispatch(clubUpdate(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          navigate(-1);
          resetForm();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const validationscheme = Yup.object({
    clubName: Yup.string().required(" Club name is required"),
    email: Yup.string()
      .required(" Email is required")
      .email(" Invalid email address"),
    // registrationNumber: Yup.string().required(" Registration number is required"),
    headquartersName: Yup.string().required(" City name is required"),
    address: Yup.string().required(" Address is required"),
    description: Yup.string().required(" Description is required"),
  });

  return (
    <div className="edit-section">
      <div className="page_name_heading_cont">
        <div className="container">
          <h5>Edit Club Details</h5>
        </div>
      </div>
      <div className="py-5">
        <div className="container">
          <div className="edit-form shadow-box">
            {!loading && (
              <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                validationSchema={validationscheme}
              >
                {(formik) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <label htmlFor="clubName">Club Title</label>
                        <Field
                          className="form-control"
                          placeholder="Club Name"
                          name="clubName"
                        />
                        <ErrorMessage
                          name="clubName"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="email">Email</label>
                        <Field
                          className="form-control"
                          name="email"
                          placeholder="Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="startDate">Start Date</label>
                        <DatePicker
                          className="form-control w-100"
                          selected={startDate}
                          onChange={(date) => {
                            formik.setFieldValue("startDate", date);
                            setStartDate(date);
                          }}
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="headquartersName">City</label>
                        <Field
                          className="form-control"
                          name="headquartersName"
                          placeholder="City name"
                        />
                        <ErrorMessage
                          name="headquartersName"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-md-6 mb-4">
                        <label htmlFor="address">Address</label>
                        <Field
                          className="form-control"
                          name="address"
                          placeholder="Address"
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-md-12 mb-4">
                        <label htmlFor="description">Description</label>
                        <Field
                          className="form-control"
                          name="description"
                          component="textarea"
                          rows="4"
                          placeholder="Description"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="upload_image_container">
                          <img
                            src={showimage}
                            alt="logo"
                            className="img-fluid uploaded_image "
                          />
                          <label htmlFor="upload-photo1">Upload Photo</label>
                          <input
                            type="file"
                            name="clubLogo"
                            id="upload-photo1"
                            onChange={handleImageUpload}
                            accept=".pjg, .png, .jpeg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-3">
                        <button
                          type="submit"
                          name="back"
                          className="btn btn-success w-100 mt-4"
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          className="btn btn-secondary w-100 mt-4"
                          onClick={() =>
                            navigate("/clubs", { state: { previousPageCount } })
                          }
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            <div className="change-password-section mt-5">
              <Formik
                initialValues={passwordValues}
                onSubmit={handlePasswordSubmit}
                validationSchema={passwordValidationSchema}
                enableReinitialize
              >
                {({ handleSubmit, handleChange, values, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="mb-3">
                        <label htmlFor="currentPassword">
                          Current Password
                        </label>
                        <Field
                          type="password"
                          name="currentPassword"
                          className="form-control"
                          placeholder="Current Password"
                        />
                        <ErrorMessage
                          name="currentPassword"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="newPassword">New Password</label>
                        <Field
                          type="password"
                          name="newPassword"
                          className="form-control"
                          placeholder="New Password"
                        />
                        <ErrorMessage
                          name="newPassword"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="confirmPassword">
                          Confirm New Password
                        </label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm New Password"
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-3">
                        <button
                          type="submit"
                          className="btn btn-success w-100 mt-2"
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClubProfile;
