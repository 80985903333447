import { createAsyncThunk } from "@reduxjs/toolkit";
// const BASE_URL = "http://3.111.234.55:6009";
// const MARKET_URL = "http://3.111.234.55:6006";
// const CLUB_URL = "http://3.111.234.55:6007";
// const BASE_URL = 'http://111.118.252.246:6009';
// const MARKET_URL = 'http://111.118.252.246:6006';
// const CLUB_URL = 'http://111.118.252.246:6007';
const BASE_URL = "https://api.mytra.club";
const MARKET_URL = "https://api.mytra.club";
const CLUB_URL = "https://api.mytra.club";
console.log(BASE_URL, "BASE_URL");

export const login = createAsyncThunk("login", async (body) => {
  const res = await fetch(`${BASE_URL}/auth/admin/admin-login`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  });

  return res?.json();
});

export const DashboardDetails = createAsyncThunk(
  "DashboardDetails",
  async (id) => {
    const res = await fetch(`${BASE_URL}/admin/dashboard?clubId=${id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const UserLists = createAsyncThunk("users/List", async (body) => {
  const res = await fetch(
    `${BASE_URL}/admin/user-list?page=${body.page}&limit=${body.limit}&search=${body.search}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const GroupLists = createAsyncThunk("groups/List", async (body) => {
  const res = await fetch(
    `${BASE_URL}/admin/group-list?page=${body.page}&limit=${body.limit}&search=${body.search}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const EventLists = createAsyncThunk("events/List", async (body) => {
  const res = await fetch(
    `${BASE_URL}/admin/events-list?page=${body.page}&limit=${body.limit}&search=${body.search}&clubId=${body.id}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const ClubLists = createAsyncThunk("ClubLists", async (body) => {
  const res = await fetch(
    `${BASE_URL}/admin/club-list?page=${body.page}&limit=${body.limit}&search=${body.search}&clubId=${body.id}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const changeClubPassword = createAsyncThunk(
  "changeClubPassword",
  async (body) => {
    const res = await fetch(`${CLUB_URL}/clubs/change-password`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    });

    const data = await res.json();

    return data;
  }
);

export const BrandLists = createAsyncThunk("BrandLists", async (body) => {
  const res = await fetch(
    `${BASE_URL}/admin/brand-list?page=${body.page}&limit=${body.limit}&search=${body.search}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const VendorLists = createAsyncThunk("VendorLists", async (body) => {
  const res = await fetch(
    `${MARKET_URL}/api/v1/vendor-list?page=${body.page}&limit=${body.limit}&search=${body.search}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const ColorLists = createAsyncThunk("ColorLists", async (body) => {
  const res = await fetch(
    `${MARKET_URL}/api/v1/color-list?page=${body.page}&limit=${body.limit}${
      body.vendor_id ? "&vendor_id=" + body.vendor_id : ""
    }`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const SizeLists = createAsyncThunk("SizeLists", async (body) => {
  const res = await fetch(
    `${MARKET_URL}/api/v1/size-list?page=${body.page}&limit=${body.limit}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const ProductLists = createAsyncThunk("ProductLists", async (body) => {
  const res = await fetch(
    `${MARKET_URL}/api/v1/product-list?limit=${body.limit}&page=${body.page}&search=${body.search}&vendor_id=${body.id}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const ClubRequestLists = createAsyncThunk(
  "ClubRequestLists",
  async (body) => {
    const res = await fetch(
      `${CLUB_URL}/clubs/clubs-list?type=request&page=${body.page}&pageSize=${body.limit}&search=${body.search}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);
export const ClubRequestById = createAsyncThunk(
  "ClubRequestById",
  async (clubId) => {
    const res = await fetch(`${CLUB_URL}/clubs/clubs-list?clubId=${clubId}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const BannerImageLists = createAsyncThunk(
  "BannerImageLists",
  async () => {
    const res = await fetch(`${MARKET_URL}/api/v1/banners-list`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const ReportList = createAsyncThunk("ReportList", async (body) => {
  const res = await fetch(
    `${BASE_URL}/admin/report-list?limit=${body.limit}&page=${body.page}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const FeedbackList = createAsyncThunk("FeedbackList", async (body) => {
  const res = await fetch(
    `${BASE_URL}/admin/feedback-list?limit=${body.limit}&page=${body.page}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const ViewSetting = createAsyncThunk("ViewSetting", async () => {
  const res = await fetch(`${BASE_URL}/auth/admin/setting-view`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const UserDelete = createAsyncThunk("UserDelete", async (id) => {
  const res = await fetch(`${BASE_URL}/admin/user-delete?user_id=${id}`, {
    method: "delete",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const GroupDelete = createAsyncThunk("GroupDelete", async (groupId) => {
  const res = await fetch(`${BASE_URL}/admin/delete-group?groupId=${groupId}`, {
    method: "delete",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const ClubDelete = createAsyncThunk("ClubDelete", async (clubId) => {
  const res = await fetch(`${BASE_URL}/admin/delete-club?clubId=${clubId}`, {
    method: "delete",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const BrandDelete = createAsyncThunk("BrandDelete", async (id) => {
  const res = await fetch(`${BASE_URL}/admin/delete-brand?brand_id=${id}`, {
    method: "delete",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const VendorDelete = createAsyncThunk("VendorDelete", async (id) => {
  const res = await fetch(
    `${MARKET_URL}/api/v1/delete-vendor?vendor_id=${id}`,
    {
      method: "delete",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const ColorDelete = createAsyncThunk("ColorDelete", async (id) => {
  const res = await fetch(`${MARKET_URL}/api/v1/delete-color?colors_id=${id}`, {
    method: "delete",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const SizeDelete = createAsyncThunk("SizeDelete", async (id) => {
  const res = await fetch(`${MARKET_URL}/api/v1/delete-size?size_id=${id}`, {
    method: "delete",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const BannerImageDelete = createAsyncThunk(
  "BannerImageDelete",
  async (id) => {
    const res = await fetch(
      `${MARKET_URL}/api/v1/banners-delete?banner_id=${id}`,
      {
        method: "delete",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const productDelete = createAsyncThunk("productDelete", async (id) => {
  const res = await fetch(
    `${MARKET_URL}/api/v1/delete-product?product_id=${id}`,
    {
      method: "delete",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const viewUserDetail = createAsyncThunk("viewUserDetail", async (id) => {
  const res = await fetch(`${BASE_URL}/admin/user-list-by-id?user_id=${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const viewGroupDetail = createAsyncThunk(
  "viewGroupDetail",
  async (id) => {
    const res = await fetch(`${BASE_URL}/admin/group-detail?groupId=${id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const viewEventDetail = createAsyncThunk(
  "viewEventDetail",
  async (id) => {
    const res = await fetch(`${BASE_URL}/admin/event-list-by-id?id=${id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const eventDelete = createAsyncThunk("eventDelete", async (id) => {
  const res = await fetch(`${BASE_URL}/admin/delete-event?eventId=${id}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const viewClubDetail = createAsyncThunk(
  "viewClubDetail",
  async (clubId) => {
    const res = await fetch(
      `${BASE_URL}/admin/club-get-by-id?clubId=${clubId}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const viewBrandDetail = createAsyncThunk(
  "viewBrandDetail",
  async (brand_id) => {
    const res = await fetch(
      `${BASE_URL}/admin/brand-list?brand_id=${brand_id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const viewVendorDetail = createAsyncThunk(
  "viewVendorDetail",
  async (id) => {
    const res = await fetch(
      `${MARKET_URL}/api/v1/vendor-list?vendor_id=${id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const viewColorDetail = createAsyncThunk(
  "viewColorDetail",
  async (id) => {
    const res = await fetch(`${MARKET_URL}/api/v1/color-list?color_id=${id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const viewReportsDetail = createAsyncThunk(
  "viewReportsDetail",
  async (id) => {
    const res = await fetch(`${BASE_URL}/admin/report-list?id=${id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const productView = createAsyncThunk("productView", async (id) => {
  const res = await fetch(`${MARKET_URL}/api/v1/product-list?id=${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const userStatusUpdate = createAsyncThunk(
  "userStatusUpdate",
  async (body) => {
    const res = await fetch(`${BASE_URL}/admin/user-status-update`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const groupStatusUpdate = createAsyncThunk(
  "groupStatusUpdate",
  async (body) => {
    const res = await fetch(`${BASE_URL}/admin/group-status-update`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const eventStatusUpdate = createAsyncThunk(
  "eventStatusUpdate",
  async (body, id) => {
    const res = await fetch(
      `${BASE_URL}/admin/update-active-status?id=${body.event_id}`,
      {
        method: "post",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const clubStatusUpdate = createAsyncThunk(
  "clubStatusUpdate",
  async (body) => {
    console.log(body);
    const res = await fetch(`${BASE_URL}/admin/club-status-update`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const brandStatusUpdate = createAsyncThunk(
  "brandStatusUpdate",
  async (body) => {
    const res = await fetch(`${BASE_URL}/admin/brand-status-update`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const vendorStatusUpdate = createAsyncThunk(
  "vendorStatusUpdate",
  async (body) => {
    const res = await fetch(
      `${MARKET_URL}/api/v1/vendor-status-update?id=${body?.id}&is_active=${body?.status}`,
      {
        method: "post",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const colorStatusUpdate = createAsyncThunk(
  "colorStatusUpdate",
  async (body) => {
    const res = await fetch(
      `${MARKET_URL}/api/v1/color-status-update?id=${body?.id}&is_active=${body?.status}`,
      {
        method: "post",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const sizeStatusUpdate = createAsyncThunk(
  "sizeStatusUpdate",
  async (body) => {
    const res = await fetch(
      `${MARKET_URL}/api/v1/size-status-update?id=${body?.id}&is_active=${body?.status}`,
      {
        method: "post",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const productStatusUpdate = createAsyncThunk(
  "productStatusUpdate",
  async (body) => {
    const res = await fetch(
      `${MARKET_URL}/api/v1/product-status-update?id=${body?.id}&is_active=${body?.status}`,
      {
        method: "post",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const editVendorDetails = createAsyncThunk(
  "editVendorDetails",
  async (body) => {
    const res = await fetch(
      `${MARKET_URL}/api/v1/vendor-update-profile?vendorId=${body.id}`,
      body.data
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const editAdminDetails = createAsyncThunk(
  "editAdminDetails",
  async (body) => {
    const res = await fetch(`${BASE_URL}/auth/admin/admin-profile-update`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const editSetting = createAsyncThunk("editSetting", async (body) => {
  const res = await fetch(`${BASE_URL}/auth/admin/setting-update?id=1`, {
    method: "post",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const clubAdd = createAsyncThunk("clubAdd", async (body) => {
  const res = await fetch(`${BASE_URL}/admin/create-club`, body);

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const brandAdd = createAsyncThunk("brandAdd", async (body) => {
  const res = await fetch(`${BASE_URL}/admin/create-brand`, body);

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const vendorAdd = createAsyncThunk("vendorAdd", async (body) => {
  const res = await fetch(`${MARKET_URL}/api/v1/create-vendor`, body);

  // if (!res.ok) {
  //   throw new Error('Network response was not ok');
  // }

  const data = await res.json();

  return data;
});

export const colorAdd = createAsyncThunk("colorAdd", async (body) => {
  const res = await fetch(`${MARKET_URL}/api/v1/create-color`, {
    method: "post",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(body),
  });

  // if (!res.ok) {
  //   throw new Error('Network response was not ok');
  // }

  const data = await res.json();

  return data;
});

export const sizeAdd = createAsyncThunk("sizeAdd", async (body) => {
  const res = await fetch(`${MARKET_URL}/api/v1/create-size`, {
    method: "post",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const productAdd = createAsyncThunk("productAdd", async (body) => {
  const res = await fetch(`${MARKET_URL}/api/v1/create-product`, body);

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const modelAdd = createAsyncThunk("modelAdd", async (body) => {
  const res = await fetch(
    `${BASE_URL}/admin/create-model?brand_id=${body.brand_id}`,
    {
      method: "post",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body.body),
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const bannerImageAdd = createAsyncThunk(
  "bannerImageAdd",
  async (body) => {
    const res = await fetch(`${MARKET_URL}/api/v1/upload-banners`, body);

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const userUpdate = createAsyncThunk("userUpdate", async (body) => {
  const res = await fetch(`${BASE_URL}/admin/update-user`, body);

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const eventUpdate = createAsyncThunk("eventUpdate", async (body) => {
  const res = await fetch(
    `${BASE_URL}/admin/update-event?id=${body.event_Id}`,
    {
      method: "post",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body.data),
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const clubUpdate = createAsyncThunk("clubUpdate", async (body) => {
  console.log(body);
  const res = await fetch(
    `${BASE_URL}/admin/update-club?id=${body.clubId}`,
    body.body
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const groupUpdate = createAsyncThunk("groupUpdate", async (body) => {
  console.log(body);
  const res = await fetch(
    `${BASE_URL}/admin/update-group?groupId=${body.groupId}`,
    body.body
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const brandUpdate = createAsyncThunk("brandUpdate", async (body) => {
  console.log(body);
  const res = await fetch(
    `${BASE_URL}/admin/update-brand?brand_id=${body.brand_id}`,
    body.body
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const vendorUpdate = createAsyncThunk("vendorUpdate", async (body) => {
  const res = await fetch(`${MARKET_URL}/api/v1/update-vendor`, body);

  // if (!res.ok) {
  //   throw new Error('Network response was not ok');
  // }

  const data = await res.json();

  return data;
});

export const productUpdate = createAsyncThunk("productUpdate", async (body) => {
  const res = await fetch(`${MARKET_URL}/api/v1/update-product`, body);

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const viewBrandModels = createAsyncThunk(
  "viewBrandModels",
  async (brand_id) => {
    const res = await fetch(
      `${BASE_URL}/admin/model-view?brand_id=${brand_id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const categoryList = createAsyncThunk("categoryList", async (body) => {
  const res = await fetch(`${MARKET_URL}/api/v1/category-list`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

export const AcceptRejectStatus = createAsyncThunk(
  "AcceptRejectStatus",
  async (body) => {
    const res = await fetch(`${CLUB_URL}/clubs/accept-reject-club`, {
      method: "put",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

export const ViewProfile = createAsyncThunk("ViewProfile", async (body) => {
  const res = await fetch(
    `${BASE_URL}/auth/admin/profile-view?role=${body.role}&id=${body.id}`,
    {
      method: "get",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await res.json();

  return data;
});

//-------------------------------------CATEGORY SECTION---------------------------------------------

// CATEGORY LIST

export const CategoriesList = createAsyncThunk("CategoryList", async (body) => {
  const res = await fetch(
    `${MARKET_URL}/api/v1/category-list?page=${body.page}&limit=${body.limit}&search=${body.search}&vendor_id=${body.id} `,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await res.json();
  return data;
});

// CATEGORY STATUS UPDATE

export const CategoryStatusUpdate = createAsyncThunk(
  "CategoryStatusUpdate",
  async (body) => {
    const res = await fetch(
      `${MARKET_URL}/api/v1/category-status-update?id=${body?.id}&is_active=${body?.status}`,
      {
        method: "post",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

// CATEGORY DELETE

export const CategoryDelete = createAsyncThunk("CategoryDelete", async (id) => {
  try {
    const res = await fetch(`${MARKET_URL}/api/v1/delete-category?id=${id}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error in CategoryDelete API:", error);
    throw error;
  }
});

// CATEGORY ADD

export const CategoryAdd = createAsyncThunk("CategoryAdd", async (body) => {
  const res = await fetch(`${MARKET_URL}/api/v1/create-category`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(body),
  });

  const data = await res.json();

  return data;
});

// VIEW CATEGORY DETAIL

export const viewCategoryDetail = createAsyncThunk(
  "viewCategoryDetail",
  async (id) => {
    const res = await fetch(`${MARKET_URL}/api/v1/category-list?id=${id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data;
  }
);

// CATEGORY EDIT

export const CategoryUpdate = createAsyncThunk(
  "CategoryUpdate",
  async (body) => {
    console.log(body);
    try {
      const res = await fetch(
        `${MARKET_URL}/api/v1/update-category?id=${body.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming token needs to be sent as Bearer token
            "Content-Type": "application/json", // Specify content type as JSON
          },
          body: JSON.stringify(body.data), // Convert body object to JSON string
        }
      );

      if (!res.ok) {
        throw new Error("Failed to update category");
      }

      const response = await res.json();
      return response;
    } catch (error) {
      console.error("Error in CategoryUpdate API:", error);
      throw error;
    }
  }
);

//-------------------------------------------------------------------------------------------------------

// EXEL IMPORT API -------------------------------------------------------------------------------------------------------

export const memberExcelImport = createAsyncThunk(
  "memberExcelImport",
  async (body) => {
    try {
      const res = await fetch(`${CLUB_URL}/clubs/import-club-members`, body);

      if (!res.ok) {
        throw new Error("Failed to update category");
      }

      const response = await res.json();
      return response;
    } catch (error) {
      console.error("Error in CategoryUpdate API:", error);
      throw error;
    }
  }
);

export const getOrder = createAsyncThunk("getOrder", async (data) => {
  const { vendor_id, page, limit, search } = data;

  // Build the query string based on the parameters
  const queryParams = new URLSearchParams({
    vendor_id,
    page: page || 1,
    limit: limit || 10, // Default limit to 10 if not provided
  });

  if (search) {
    queryParams.append("search", search);
  }

  // Construct the API URL with query parameters
  const apiUrl = `${MARKET_URL}/api/v1/get-order?${queryParams.toString()}`;

  const res = await fetch(apiUrl, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  const data1 = await res.json();
  return data1;
});
export const viewOrderDetail = createAsyncThunk(
  "viewOrderDetail",
  async (order_id) => {
    // console.log(data.vendor_id)
    const res = await fetch(
      `${MARKET_URL}/api/v1/get-order?order_id=${order_id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    const data1 = await res.json();
    return data1;
  }
);

export const generateAWB = await createAsyncThunk(
  "generateAWB",
  async (body) => {
    const response = await fetch(`${MARKET_URL}/api/v1/generate-awb`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  }
);

//---------------------------------Vendor Inquiry------------------------

export const getVendorInquires = createAsyncThunk(
  "getVendorInquires",
  async (data) => {
    // console.log(data.vendor_id)
    const { id, page, limit } = data;
    const res = await fetch(
      `${BASE_URL}/admin/get-vendor-inquiry?page=${page}&limit=${limit}&${
        id && "id=" + id
      }`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    const data1 = await res.json();
    return data1;
  }
);
